// assets/styles/global.scss

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";



* {
    box-sizing: border-box;
}
body {
  padding: 0;
  background: url(../images/6c1d7b_039de17416444ed3a561901fdb2f2241.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  background-color: #121415 !important;
}
.bg-dark { 
  background-color: #343536 !important;
}
h2 {
  font-size: 32px;
  font-weight: bolder;
}
.card {
}
.container {
  background: transparent;
}
ul {
  
}
.banner {
  padding: 20px;
  margin-top: 20px;
  max-height: 150px;
  width: 100%;
}
.banner > img {
  max-width: 200px;
  max-height: 150px;
  padding: 10px;
}
.banner > h1 {
  font-size: 60px;
  text-transform: uppercase;
}
.main-title {
  color: white;
  font-size: 64px;
  font-weight: bolder;
  /*transform: translateY(180px);*/
}
.apple-music {
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: white;
  color: black;
  fill:currentColor;
  cursor: pointer;
  display: block;
  text-align: center;
  margin-right: 25px;
}
.apple-music:hover {
  background-color: black;
  color:white;
  fill:currentColor;
}
.spotify {
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: black;
  cursor: pointer;
  color:white;
  fill:currentColor;
  display: block;
  text-align: center;
}
.spotify:hover {
  background-color: green;
  color:black;
  fill:currentColor;
}
.artist-picture {
  width: 350px;
  height: 350px;
  margin: 20px;
  border-radius: 100%;
  box-shadow: 0px 4px 20px -1px rgba(0,0,0,0.75);
}
